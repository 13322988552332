import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { ServicioAutenticacion } from '../_services/autenticacion.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: ServicioAutenticacion) { }
    private static accessTokenError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                if (!ErrorInterceptor.accessTokenError$.getValue()) {

                    ErrorInterceptor.accessTokenError$.next(true);
                    return this.authenticationService.refreshToken().pipe(
                        switchMap((event: any) => {
                            ErrorInterceptor.accessTokenError$.next(false);
                            const currentAccessToken = this.authenticationService.currentAccessTokenValue;
                            const currentPermission = this.authenticationService.currentPermissionValue;
                            const newRequest = request.clone({
                                setHeaders: {
                                    'Content-Type': `application/json`,
                                    'Authorization': `Bearer ${currentAccessToken}`,
                                    'X-Permission': currentPermission,
                                    'IdSuscripcion': this.authenticationService.getCurrentUserPermissionValue().IdEmpresa.toString(),
                                    'IdPortal': 'Ax8='
                                }
                            });
                            return next.handle(newRequest);
                        }),
                        catchError(er => {
                            console.log(er);
                            this.authenticationService.logout();
                            return throwError(er);
                        })
                    );
                } else {
                    const currentAccessToken = this.authenticationService.currentAccessTokenValue;
                    const currentPermission = this.authenticationService.currentPermissionValue;
                    return this.waitNewTokens().pipe(
                        switchMap((event: any) => {
                            const newRequest = request.clone({
                                setHeaders: {
                                    'Content-Type': `application/json`,
                                    'Authorization': `Bearer ${currentAccessToken}`,
                                    'X-Permission': currentPermission,
                                    'IdSuscripcion': this.authenticationService.getCurrentUserPermissionValue().IdEmpresa.toString(),
                                    'IdPortal': 'Ax8='
                                }
                            });
                            return next.handle(newRequest);
                        })
                    );
                }
            } else if (err.status === 403) {
                console.log(err);
                this.authenticationService.logout();
            }

            const error = err.error.message || err.statusText;

            return throwError(error);
        }));
    }

    private waitNewTokens(): Observable<any> {
        const subject = new Subject<any>();
        const waitToken$: Subscription = ErrorInterceptor.accessTokenError$.subscribe((error: boolean) => {
            if (!error) {
                subject.next;
                waitToken$.unsubscribe();
            }
        });
        return subject.asObservable();
    }
}
