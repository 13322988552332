export const environment = {
  production: false,
  wootricCsat: "eb67c1d7",
  wootricCes: "16ddf57c",
  wootricDev: true,
  urlApiNmp: 'https://qa-api-nmp.azurewebsites.net',
  urlMarketplaceCore: 'https://dev-apim-ic.azure-api.net/nmp-sso/',
  url: "https://qa-api-cotizaciones.azurewebsites.net/",
  urlMarketplace: "https://apimarketplace-des.iconstruye.cl/",
  urlArchivo: "https://qa-api-archivos.azurewebsites.net/",
  urlEmpresa: "https://qa-api-consulta-emp.azurewebsites.net",
  clientIdNmp: "4upmvpbu3is9o4tae36qpkvla7",
  instrumentationKey: 'b4e8b404-0235-419f-a993-a07b43a15447',
  urlApiHook: 'https://qa-api-nmp-hook.azurewebsites.net/',
  urlJwtMetabase: 'https://qa-api-metabase.azurewebsites.net/',
  urlApiSSO: 'https://qa-api-login.azurewebsites.net/',
  domainCookie: 'iconstruye.cl',
  ulrDashboard: 'https://marketplacessoqa.iconstruye.cl/',
  urlApiConfiguracion :'https://qa-api-gateway.iconstruye.cl/configuracion/',
  urlApiCompra :'https://qa-api-gateway.iconstruye.cl/compra/',
  // ApiBasicUser : "AppApiCompra",
  // ApiBasicPassword : "0sk5QdFyg7*j",
  //UseBasic: true
};

