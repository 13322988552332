import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { from, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { interfaces } from "../_models/interfaces";
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserPermission } from '../_models/UserPermission.model';
import { Usuario } from "../_models/usuario";

export interface LoginResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    userName: string;
    ".issued": string;
    ".expires": string;
}

export interface ISecurityUser {
    userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ServicioAutenticacion {

    static tokenKey = "icMarketPlaceToken";
    static portalKey = "icMarketPlacePortalKey";
    static suscripcionKey = "icMarketPlaceSuscripcionKey";
    static currentUser = "icCurrentUser";
    static currentSuscripcion = "icCurrentSuscription";

    constructor(private http: HttpClient, private cookieService: CookieService, private jwtHelper: JwtHelperService) {}

    public estaAutenticado(): boolean {
        return this.currentIdTokenValue != null;
    }

    public get currentAccessTokenValue(): string {
        return this.cookieService.get('accessToken');
    }

    public get currentIdTokenValue(): string {
        return this.cookieService.get('idToken');
    }

    public get currentPermissionValue(): string {
        return this.cookieService.get('tokenPermisos');
    }

    public get currentRefreshTokenValue(): string {
        return this.cookieService.get('refreshToken');
    }

    // public obtenerPortal(): string {
    //     const objJson = sessionStorage.getItem(ServicioAutenticacion.portalKey);
    //     return objJson ?? "";
    // }

    // public obtenerIdSuscripcion(): string {
    //     const objJson = sessionStorage.getItem(ServicioAutenticacion.suscripcionKey);
    //     return objJson ?? "";
    // }

    // public obtenerCurrentUser(): interfaces.UsuarioActual {
    //     const objJson = sessionStorage.getItem(ServicioAutenticacion.currentUser);
    //     return objJson !== null ? JSON.parse(objJson) : null;
    // }

    // public obtenerCurrentSuscripcion(): interfaces.Suscripcion {
    //     const objJson = sessionStorage.getItem(ServicioAutenticacion.currentSuscripcion);
    //     return objJson !== null ? JSON.parse(objJson) : null;
    // }

    // public salir() {
    //     sessionStorage.removeItem(ServicioAutenticacion.tokenKey);
    //     sessionStorage.removeItem(ServicioAutenticacion.portalKey);
    //     sessionStorage.removeItem(ServicioAutenticacion.suscripcionKey);
    //     sessionStorage.removeItem(ServicioAutenticacion.currentUser);
    // }

    // public ingresarToken(token: string): Observable<ISecurityUser> {
    //     sessionStorage.setItem(ServicioAutenticacion.tokenKey, token);
    //     const usuario = <ISecurityUser>{ userName: "Por token" };
    //     return from(Promise.resolve(usuario));
    // }

    public ingresarPortalSuscripcion(idportal: string, idsuscripcion: string, currentUser: interfaces.UsuarioActual, dataHotjar: string, currentSuscripcion: interfaces.Suscripcion): Observable<ISecurityUser> {
        sessionStorage.setItem(ServicioAutenticacion.portalKey, idportal);
        sessionStorage.setItem(ServicioAutenticacion.suscripcionKey, idsuscripcion);
        sessionStorage.setItem(ServicioAutenticacion.tokenKey, currentUser.idToken);
        sessionStorage.setItem(ServicioAutenticacion.currentUser, JSON.stringify(currentUser));
        sessionStorage.setItem(ServicioAutenticacion.currentSuscripcion, JSON.stringify(currentSuscripcion));
        localStorage.setItem("dataHotjar", dataHotjar);
        const usuario = <ISecurityUser>{ userName: "Portal/Suscripcion" };
        return from(Promise.resolve(usuario));
    }

    public execute(): Observable<string> {
        if (!this.expiredToken()) {
            return from(Promise.resolve("OK"));
        }
        return this.refreshToken().pipe(map(_ => { return "OK" }))
    }

    public expiredToken(): boolean {
        const token = this.currentAccessTokenValue;
        if (token === '') {
            return true;
        }
        const tokenJson = JSON.parse(atob(token.split(".")[1]));
        const fecha = tokenJson.exp;
        return Math.round(Date.now() / 1000) >= fecha;
    }

    private refreshTokenTimeout: any;
    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentAccessTokenValue.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    start() {
        this.startRefreshTokenTimer();
    }

    refreshToken(): Observable<void> {
        if (!this.currentRefreshTokenValue) this.logout();

        return this.http.get<any>(`${environment.urlApiSSO}api/v1/autenticacion/refrescartoken?token=${this.currentRefreshTokenValue}`)
            .pipe(map(data => {
                if (data) {
                    this.saveAccessToken(data.accessToken);
                    this.start();
                }
                else {
                    this.logout();
                }
            }));
    }

    logout() {
        this.stopRefreshTokenTimer();
        window.parent.location.href = `${environment.ulrDashboard}/public?returnUrl=/private/container/externalapp?url=${window.location.href}`;
    }

    saveAccessToken(accessToken: string) {
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('accessToken', accessToken, d, '/', environment.domainCookie, false, 'Lax');
    }

    saveIdToken(idToken: string) {
        const jwtToken = JSON.parse(atob(idToken.split('.')[1]));
        const d = new Date(0);
        d.setUTCSeconds(jwtToken.exp);
        this.cookieService.set('currentUser', idToken, d, '/', environment.domainCookie, false, 'Lax');
    }

      public getCurrentUserPermissionValue(): UserPermission {
        if (this.cookieService.check('tokenPermisos')) {
          let suscriptionTokenPermisos = JSON.stringify(this.jwtHelper.decodeToken(this.currentPermissionValue));
          let suscriptionObj = <any>JSON.parse(suscriptionTokenPermisos);
          let enterpriseSelected = <any>JSON.parse(atob(suscriptionObj.permisos));
    
          const userPermission: UserPermission = new UserPermission();
    
          userPermission.IdEmpresa = enterpriseSelected.IdEmpresa;
          userPermission.IdOrganizacion = enterpriseSelected.IdOrganizacion;
          userPermission.IdUsuario = enterpriseSelected.IdUsuario;
          userPermission.Pi = enterpriseSelected.Pi;
          userPermission.Ruts = enterpriseSelected.Ruts;
          userPermission.AdminCartera = enterpriseSelected.AdminCartera;  
          return userPermission;
    
        }
        return new UserPermission();
      }

      public getCurrentUserValue(): Usuario {
        let usuario: any;
        if (this.cookieService.check('currentUser')) {
          let currentUser = this.jwtHelper.decodeToken(this.cookieService.get('currentUser'));
            
          usuario = new Usuario({  
          EmailUsuario : currentUser["email"],
          Empresa : "",
          IdUsuario : this.getCurrentUserPermissionValue().IdUsuario,
          EsComprador: false,
          EsProveedor : true,
          IdCliente:"",
          Nombre : currentUser["given_name"] + " "+ currentUser["family_name"],
          TamanoEmpresa : ""
          });
          return usuario;
    
        }
        return new Usuario(usuario);
      }

      public getcurrentUsernameValueFromCookie(): string {
        if (this.cookieService.check('tokenPermisos')) {
          let suscriptionTokenPermisos = JSON.stringify(this.jwtHelper.decodeToken(this.currentPermissionValue));
          let suscriptionObj = <any>JSON.parse(suscriptionTokenPermisos);
          let userSelected = <any>JSON.parse(atob(suscriptionObj.permisos));
          return userSelected.email;
        }
        return "";
      }

}
