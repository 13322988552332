import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderGridComponent } from './loader-grid/loader-grid.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [LoaderComponent, LoaderGridComponent],
  imports: [
    FontAwesomeModule,
    CommonModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    LoaderComponent,
    LoaderGridComponent
  ]
})
export class LoaderModule { }
