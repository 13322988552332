import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApplicationInsightsService } from './_services/application-insights.service';
import { ServicioAutenticacion } from './_services/autenticacion.service';
import { SessionService } from './_services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'CotizacionSPA';
  loading: boolean = false;
  constructor(private sessionService: SessionService,
    private autenticationService: ServicioAutenticacion,
    private router: Router,
    private applicationInsightsService: ApplicationInsightsService
  ) {

    //this.sessionService.reset();
  }

  public ngOnInit() {
    this.autenticationService.start();
  }

}
