import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
    private keys: any = {};
    constructor() {
    }
    public reset() {
    }
    public set(id: string, value: any): this {
        this.keys[id] = id;
        if (typeof value === 'object') value = JSON.stringify(value);
        sessionStorage.setItem(id, value);
        return this;
    }
    public get(id: string): any | undefined {
        const value = sessionStorage.getItem(id);
        try {
            return JSON.parse(value || '');
        } catch (e) {
            return value;
        }
    }
}
