import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 100 * 1000)
        .subscribe(() => updates.checkForUpdate()
          .then(() => {
            localStorage.setItem('last-version-cz', 'true');
          }));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    setTimeout(() => {
      localStorage.setItem('last-version-cz', 'false');
    }, 5000);
  }
}
