import { Component, EventEmitter, HostListener, OnInit, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../_services/loader.service';
import { LoaderState } from '../loader';
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-loader-grid',
  templateUrl: './loader-grid.component.html',
  styleUrls: ['./loader-grid.component.css']
})
export class LoaderGridComponent implements OnInit {
  faSpinnerThird = faSpinnerThird;
  show = true;
  innerWidth: number | undefined;
  private subscription: Subscription = new Subscription();
  @Output() loading = new EventEmitter<boolean>();
  @Input() resize: boolean = false;
  constructor(private loaderService: LoaderService) { }
  ngOnInit(): void {
    this.subscription = this.loaderService.loaderGridState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.loading.emit(this.show);
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
