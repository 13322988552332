import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import { ApplicationInsightsService } from "./_services/application-insights.service";
import { ServicioAutenticacion } from "./_services/autenticacion.service";
declare var $: any;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Component({
    selector: "app-login",
    template: ""
})

export class IngresoComponent implements OnInit {

    constructor(
        private servicioAutenticacion: ServicioAutenticacion,
        private aiService: ApplicationInsightsService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient) {
    }

    public onSubmit() {
    }

    private handleMessage = (event: interfaces.ParentEvent) => {
        const dataFromParent = event.data;
        if (dataFromParent.currentUser != null) {
            const currentUser: interfaces.UsuarioActual = JSON.parse(dataFromParent.currentUser);
            const currentSuscripcion: interfaces.Suscripcion = JSON.parse(dataFromParent.currentSuscription);
            //const currentPortal: interfaces.Portal = un;
            const dataHotjar: string = dataFromParent.dataHotjar;

            if (currentUser != null) {
                if (dataFromParent.Callback == "login" || dataFromParent.Callback == "refresh") {
                    const idPortal = "Ax8=";
                    const idSuscripcion = currentSuscripcion == null ? null : "FEO";
                    if (idSuscripcion == null) { return; }
                    this.servicioAutenticacion.ingresarPortalSuscripcion(idPortal, idSuscripcion, currentUser, dataHotjar, currentSuscripcion);
                    if (dataFromParent.Callback == "login") {
                        this.aiService.setUserId(currentUser.user_name)
                        this.listoToken(idPortal, idSuscripcion, currentUser);

                        setInterval(() => {
                            if (this.servicioAutenticacion.expiredToken()) {
                                this.servicioAutenticacion.refreshToken();
                            }
                        }, 60000);
                    }
                    if (dataFromParent.Callback == "refresh") { }
                }
            }
        }
    }

    private listoToken = (idPortal: string, idSuscripcion: string, currentUser: interfaces.UsuarioActual) => {
        this.route.paramMap.subscribe((params: ParamMap) => {

            this.route.queryParams.subscribe(aparams => {
                const path = params.get("path") || "";
                if (path) {
                    if (path.toLowerCase() == "cotizaciones") {
                        this.router.navigate([path], { queryParams: aparams });
                    }
                    if (path.toLowerCase() == "ofertarcotizacion") {
                        let urlToNavigate = `${path.toLowerCase()}/${aparams["idcz"]}/${aparams["idemp"]}//${aparams["id"] ? aparams["id"] : '0'}`
                        this.router.navigate([urlToNavigate]);
                    }
                    if (path.toLowerCase() == "reofertarcotizacion") {
                        let urlToNavigate = `${path.toLowerCase()}/${aparams["idcz"]}/${aparams["idemp"]}/${aparams["idoft"]}/${aparams["id"]}`
                        this.router.navigate([urlToNavigate]);
                    }
                    if (path.toLowerCase() == "veroferta") {
                        if (aparams["fromWS"]) {
                            let urlToNavigate = `${path.toLowerCase()}/${aparams["rutc"]}/${aparams["ruto"]}/${aparams["nc"]}/${aparams["ido"]}/${true}`
                            let detail = [
                                {
                                    Item1: "rutcliente",
                                    Item2: aparams["rutc"]
                                },
                                {
                                    Item1: "rutoferente",
                                    Item2: aparams["ruto"]
                                },
                                {
                                    Item1: "numerocotizacion",
                                    Item2: aparams["nc"]
                                },
                                {
                                    Item1: "idoferta",
                                    Item2: aparams["ido"]
                                },
                                {
                                    Item1: "user",
                                    Item2: currentUser.id.toString()
                                },
                                {
                                    Item1: "usertelefono",
                                    Item2: currentUser.telefono
                                }
                            ];
                            this.setTracking(3, detail).subscribe(() =>
                                this.router.navigate([urlToNavigate]));
                        } else {
                            let urlToNavigate = `${path.toLowerCase()}/${aparams["idcz"]}/${aparams["idemp"]}/${aparams["idoferta"]}`
                            this.router.navigate([urlToNavigate]);
                        }
                    }
                    if (path.toLowerCase() == "cotizacionesdescartadas") {
                        this.router.navigate([path]);
                    }
                    if (path.toLowerCase() == "vercotizacion") {
                        let urlToNavigate = `${path.toLowerCase()}/${aparams["idcz"]}/${aparams["idemp"]}`
                        this.router.navigate([urlToNavigate]);
                    }
                    if (path.toLowerCase() == "dashboard") {
                        this.router.navigate([path], { queryParams: aparams });
                    }
                } else {
                    this.router.navigate(["cotizaciones", { queryParams: aparams }]);
                }
            });
        });
    }

    setTracking(trackingProviderId: number, detail: any[]) {
        let data = {
            trackingProviderId: trackingProviderId,
            appName: 'Cotizaciones_VerOferta',
            detail: (detail != null) ? detail : null
        }
        return this.http.post<any>(environment.urlApiHook + 'api/Tracking?api-version=1.0', data, httpOptions)
            .pipe();
    }

    public ngOnInit() {
        localStorage.setItem("FiltrosCz", "");
        localStorage.setItem("consultaPagActual", "1");

        if (window.addEventListener) {
            window.addEventListener("message", this.handleMessage);
        } else {
            (<any>window).attachEvent("onmessage", this.handleMessage);
        }
        parent.postMessage({ RequestIdT: true, Callback: "login" }, "*");
    }
}

export namespace interfaces {
    export interface Empresa {
        id: number;
        codigo: string;
        nombre: string;
        fechacreacion: string
    }
    export interface Suscripcion {
        id: string;
        nombre: string;
        empresas: Empresa[];
    }
    export interface Portal {
        id: string;
        value: string;
        codigo: string;
    }
    export interface UsuarioActual {
        id: number;
        idToken: string;
        refreshToken: string;
        nombres: string;
        apellidos: string;
        user_name: string;
        telefono: any;
        suscripciones: Array<Suscripcion>;
        codigo: string;
        exitosa?: boolean;
        activo?: boolean;
        administrador?: boolean;
        mensajeLogin?: string;
        wizardComplete?: boolean;
        productos?: Array<number>;
        password?: string;
        confirmPassword?: string;
        federada?: boolean;
        suscripcionDefault?: string;
        fechaCreacion: Date;
        userPersona?: string;
        segment?: string;
    }

    export interface ParentMessage {
        Callback: string;
        currentPortal: string;
        currentSuscription: string;
        currentUser: string;
        dataHotjar: string;

    }
    export interface ParentEvent {
        origin: string;
        data: ParentMessage;
    }
}
