import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LoaderState } from "../loader/loader";

@Injectable({
    providedIn: 'root'
  })
export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();
    private loaderGridSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    loaderGridState = this.loaderGridSubject.asObservable();
    constructor() { }
    show() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }
    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }

    showGrid(){
      this.loaderGridSubject.next(<LoaderState>{ show: true });
    }

    hideGrid(){
      this.loaderGridSubject.next(<LoaderState>{ show: false });
    }
}
