import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './_interceptors/errorhttp.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ApplicationInsightsErrorHandler } from './_services/application-insights.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedControlsModule } from './shared-controls/shared-controls.module';
import { ServicioAutenticacion } from './_services/autenticacion.service';
import { LoaderService } from './_services/loader.service';
import { SessionService } from './_services/session.service';
import { IngresoComponent } from './ingreso.component';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { UpdateService } from './_services/update.service';
import { registerLocaleData } from "@angular/common";
import localeEsCL from "@angular/common/locales/es-CL";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderInterceptorService } from './_interceptors/loader.interceptor';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { LoaderModule } from './loader/loader.module';
import { GlobalErrorHandler } from './GlobalErrorHandler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeEsCL, 'es-CL');

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}

@NgModule({
  declarations: [
    AppComponent,
    IngresoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    LoaderModule,
    SharedControlsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:1000'
    })
  ],
  providers: [
    SessionService,
    LoaderService,
    ServicioAutenticacion,
    CookieService,
    UpdateService,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    // { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler }
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
