import { Respuesta } from "./mensajes";

export enum TipoAccion {
  AprobarOrdenCompra = 1,
  AprobarEstadoPago = 2,
  AprobarFactura = 3,
  AprobarSubContrato = 4,
  ConsultarOrdenesCompraProveedor = 5,
  ConsultarCotizacionesProveedor = 6,
}

export class Usuario implements Usuario {
  public IdCliente!: string;
  public IdUsuario!: string;
  public EmailUsuario!: string;
  public EsComprador!: boolean;
  // public TipoAccionPermisos: Dto.TipoAccionPermiso[];
  public EsProveedor!: boolean;
  public Nombre!: string;
  public Empresa!: string;
  public TamanoEmpresa!: string;
  // public PuedeEjecutar = (tipoAccion: TipoAccion) => {
  //     const tipo = <any>tipoAccion;
  //     // tslint:disable-next-line:triple-equals
  //     return this.TipoAccionPermisos.filter(t => t.TipoAccion == tipo && t.PuedeEjecutar).length > 0;
  // }

  constructor(input: Usuario) {
      if (input == null) { return; }
      this.IdCliente = input.IdCliente;
      this.EsComprador = input.EsComprador;
      this.EsProveedor = input.EsProveedor;
      this.EmailUsuario = input.EmailUsuario;
      this.IdUsuario = input.IdUsuario;
      this.Nombre = input.Nombre;
      this.Empresa = input.Empresa;
      this.TamanoEmpresa = input.TamanoEmpresa;
      // this.TipoAccionPermisos = input.TipoAccionPermisos;
  }
}
export interface UsuarioConectadoRespuesta extends Respuesta {
  Usuario: Usuario;
}

export class UsuarioAviso{
  UsuarioId!: number;
  AvisoId!: number;
}
