import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Router, NavigationEnd, Event, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;
  private isInitialPageLoad: boolean = true;
  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: false
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();

  }
  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string, properties?: {
    duration?: number;
    [key: string]: any;
  }) {
    this.appInsights.trackPageView({
      name,
      uri,
      isLoggedIn: true,
      properties
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({
      name
    }, properties);
  }
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
  logException(error: Error) {
    let exception: IExceptionTelemetry = {
      exception: error
    };
    this.appInsights.trackException(exception);
  }
  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope: any) => {
      let itemTags = envelope.tags;
      if (itemTags) {
        itemTags = itemTags || [];
        itemTags["ai.cloud.role"] = "MarketPlaceUnificado-CotizacionSPA";
        itemTags["ai.cloud.role"] = "MarketPlaceUnificado-CotizacionSPA";
        itemTags["ai.cloud.roleInstance"] = "MarketPlaceUnificado-CotizacionSPA";
        var item = envelope.baseData;
        item.properties = item.properties || {};
        item.properties["ApplicationPlatform"] = "Web";
        item.properties["ApplicationName"] = "MarketPlaceUnificado-CotizacionSPA";
        item.properties["UserEmail"] = JSON.parse(sessionStorage.getItem("icCurrentUser") || '')["email"];
      }
    });
  }

  private createRouterSubscription() {

      this.router.events.pipe(filter((event:Event): event is NavigationStart => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        if (!this.isInitialPageLoad)
          this.appInsights.startTrackPage(event.url);
        else
          this.isInitialPageLoad = false;
      });
      this.router.events.pipe(filter((event:Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        if (!this.isInitialPageLoad)
          this.appInsights.stopTrackPage(event.urlAfterRedirects);
        else
          this.isInitialPageLoad = false;
      });

      if (this.isInitialPageLoad) {
        this.logPageView(this.router.url);
      }

  }
}

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error: any): void {
    this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
  }
}
