import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BootstrapSelectDirective } from './bootstrap-select.directive';
import { SingleselectObjectReferenciaComponent } from './singleselect-object-referencia/singleselect-object-referencia.component';
import { MultiselectObjectReferenciaComponent } from '../shared-controls/multiselect-object-referencia/multiselect-object-referencia.component';

@NgModule({
    declarations: [
        BootstrapSelectDirective,
        MultiselectObjectReferenciaComponent,
        SingleselectObjectReferenciaComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        BootstrapSelectDirective,
        MultiselectObjectReferenciaComponent,
        SingleselectObjectReferenciaComponent,
    ]
  })
  export class SharedControlsModule { }