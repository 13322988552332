<select
  [compareWith]="compareReferencias"
  bootstrapSelect
  [(ngModel)]="selectedReferencias"
  [class]="customClasses"
  [title]="customTitle"
  [maxOptions]="maxOptions"
  [size]="customWidth"
  [disabled]="disabled"
>
  <option
    *ngFor="let referencia of referencias"
    [ngValue]="referencia"
    id="{{ referencia.Id }}"
  >
    {{ referencia.Nombre }}
  </option>
</select>
