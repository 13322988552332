import { Input, Output, ViewChild, EventEmitter, Directive } from '@angular/core';
import { BootstrapSelectDirective } from './bootstrap-select.directive';

/**
 * Base class to extend for a bootstrap-select based component
 */

@Directive()
export abstract class BaseBootstrapSelect {

  @ViewChild(BootstrapSelectDirective)
  bootstrapSelectDirective: BootstrapSelectDirective | undefined;

  private _options: any[] = [];
  private _optgroups: any[] = [];
  private _custClass: string = '';
  private _mySelections: any | any[];
  private _customTitle: string = '';
  private _disabled: boolean = false;
  private _customWidth!: number;
  private _maxOptions!: number;

  // accept a single string or an array of strings to support both single and multiselect
  @Input() public set mySelections(value: any | any[]) {
    this._mySelections = value;
    this.mySelectionsChange.emit(value);
    // this is necessary for bootstrap-select to pickup the current selections
    setTimeout(() => {
      this.bootstrapSelectDirective?.refresh();
    });
  }

  public get mySelections() {
    return this._mySelections;
  }

  @Input()
  set customTitle(customTitle: string) {
    this._customTitle = customTitle;
  }
  get customTitle() {
    return this._customTitle;
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  set customWidth(customWidth: number) {
    this._customWidth = customWidth;
  }
  get customWidth() {
    return this._customWidth;
  }

  @Input()
  set maxOptions(maxOptions: number) {
    this._maxOptions = maxOptions;
  }
  get maxOptions() {
    return this._maxOptions;
  }

  @Input()
  set customClasses(custClass: string) {
    this._custClass = custClass;
  }
  get customClasses() {
    return this._custClass;
  }

  @Output()
  public mySelectionsChange = new EventEmitter();

  @Input()
  set options(options: any[]) {
    this._options = options;
    setTimeout(() => {
      this.bootstrapSelectDirective?.refresh();
    });
  }

  @Input()
  set optgroups(optgroups: any) {
    this._optgroups = optgroups;
  }

  get options() {
    return this._options;
  }

  get optgroups() {
    return this._optgroups;
  }

  selectOptionById(id: string) {
    this.bootstrapSelectDirective?.selectOptionById(id);
  }
}
