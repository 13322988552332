import { Directive, ElementRef, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { faCheck } from "@fortawesome/pro-solid-svg-icons"

declare var jQuery: any;

@Directive({
    selector: '[bootstrapSelect]'
})
export class BootstrapSelectDirective implements OnInit, OnDestroy, AfterViewInit {
    private el: any;
    faCheck=faCheck;
    constructor(private elref: ElementRef) {
        this.el = elref.nativeElement;
    }

    ngOnInit() {
    }

    @Input() public maxOptions!: number;

    ngAfterViewInit() {
        let options = {
            iconBase: 'glyphicon',
            tickIcon: 'glyphicon-ok',
            width: 0,
            maxOptions: 10
        };
        if (this.el.size != null && this.el.size != "0") {
            options["width"] = this.el.size;
        }
        let mOptions = Number(this.maxOptions);
        if (mOptions > 0) {
            options["maxOptions"] = mOptions;
        }
        jQuery(this.el).selectpicker(options);
    }

    ngOnDestroy() {
        jQuery(this.el).selectpicker('destroy');
    }

    refresh() {
        jQuery(this.el).selectpicker('refresh');
    }

    triggerChangeEvent() {
        this.el.dispatchEvent(new Event('change', { 'bubbles': true }));
    }

    selectOptionById(id: string) {
        jQuery(this.el).find('#'.concat(id)).prop('selected', true);
        this.triggerChangeEvent();
    }
}
