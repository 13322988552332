import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../_services/loader.service';
import { LoaderState } from './loader';
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  faSpinnerThird = faSpinnerThird;
  show = false;
  private subscription: Subscription = new Subscription();
  @Output() loading = new EventEmitter<boolean>();
  constructor(private loaderService: LoaderService) { }
  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.loading.emit(this.show);
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
