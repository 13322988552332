<div class="panel panel-default" *ngIf="show">
  <div class="panel-heading">
    <div class="row">
      <div class="col-sm-6 f12">
        <ngx-skeleton-loader
          [theme]="{
            width: '75%',
            'border-radius': '0',
            height: '12px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-6">
        <ngx-skeleton-loader
          [theme]="{
            width: '30%',
            'border-radius': '0',
            float: 'right',
            height: '12px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div *ngIf="resize">
    <div
      *ngFor="
        let cotizacion of ['', '', '', '', '', '', '', '', '', ''];
        let i = index
      "
      class="panel panel-default col-sm-12 p10bottom p10top"
    >
    <div>
      <ul class="list-unstyled">
          <li>
              <h4 style="margin: 0px;"><ngx-skeleton-loader
                [theme]="{
                  width: '30%',
                  'border-radius': '0',
                  height: '15px'
                }"
              >
              </ngx-skeleton-loader></h4>
          </li>
          <li><strong><ngx-skeleton-loader
                    [theme]="{
                      width: '25%',
                      'border-radius': '0',
                      height: '10px'
                    }"
                  >
                  </ngx-skeleton-loader></strong>
          </li>
          <li>
            <ngx-skeleton-loader
            [theme]="{
              width: '10%',
              'border-radius': '0',
              height: '20px'
            }"
          >
          </ngx-skeleton-loader>
          </li>
      </ul>
  </div>
  <div>
    <ul class="list-unstyled">
        <li><small><ngx-skeleton-loader
          [theme]="{
            width: '15%',
            'border-radius': '0',
            height: '10px'
          }"
        >
        </ngx-skeleton-loader></small></li>
        <li><strong><ngx-skeleton-loader
          [theme]="{
            width: '100%',
            'border-radius': '0',
            height: '15px'
          }"
        >
        </ngx-skeleton-loader></strong><span
                class="text-muted"> <ngx-skeleton-loader
                [theme]="{
                  width: '5%',
                  'border-radius': '0',
                  height: '20px'
                }"
              >
              </ngx-skeleton-loader> <small><ngx-skeleton-loader
                [theme]="{
                  width: '10%',
                  'border-radius': '0',
                  height: '20px'
                }"
              >
              </ngx-skeleton-loader></small></span>
        </li>

        <li class="text-muted"><small class="f12"><ngx-skeleton-loader
          [theme]="{
            width: '10%',
            'border-radius': '0',
            height: '20px'
          }"
        >
        </ngx-skeleton-loader></small></li>
        <li class="m5top hide"><small class="f12"><ngx-skeleton-loader
          [theme]="{
            width: '50%',
            'border-radius': '0',
            height: '20px'
          }"
        >
        </ngx-skeleton-loader></small></li>
        <li><small><ngx-skeleton-loader
          [theme]="{
            width: '50%',
            'border-radius': '0',
            height: '20px'
          }"
        >
        </ngx-skeleton-loader></small></li>
        <li class="text-muted"><small class="f12"><ngx-skeleton-loader
          [theme]="{
            width: '50%',
            'border-radius': '0',
            height: '20px'
          }"
        >
        </ngx-skeleton-loader></small></li>
    </ul>
</div>
<div class="col-sm-12">
  <ngx-skeleton-loader
          [theme]="{
            width: '90%',
            'border-radius': '0',
            height: '40px'
          }"
        >
        </ngx-skeleton-loader>
</div>
    </div>
  </div>
  <div *ngIf="!resize">
    <table class="table table-condensed table-docs f12">
      <thead>
        <tr>
          <th>
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </th>
          <th>
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </th>
          <th class="td-display-col">
            <span
              ><ngx-skeleton-loader
                [theme]="{
                  width: '120px',
                  'border-radius': '0',
                  height: '12px'
                }"
              >
              </ngx-skeleton-loader
            ></span>
          </th>
          <th class="td-display-col">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </th>
          <th>
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </th>
          <th class="td-display-col" *ngIf="false">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </th>
          <th class="id-class-fapertura td-display-col">
            <span
              ><ngx-skeleton-loader
                [theme]="{
                  width: '120px',
                  'border-radius': '0',
                  height: '12px'
                }"
              >
              </ngx-skeleton-loader
            ></span>
          </th>
          <th class="id-class-fcierre">
            <span
              ><ngx-skeleton-loader
                [theme]="{
                  width: '120px',
                  'border-radius': '0',
                  height: '12px'
                }"
              >
              </ngx-skeleton-loader
            ></span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let cotizacion of ['', '', '', '', '', '', '', '', '', ''];
            let i = index
          "
        >
          <td>
            <ul class="list-unstyled">
              <li>
                <ngx-skeleton-loader
                  [theme]="{
                    width: '120px',
                    'border-radius': '0',
                    height: '12px'
                  }"
                >
                </ngx-skeleton-loader>
              </li>
              <li class="text-muted f12">
                <ngx-skeleton-loader
                  [theme]="{
                    width: '120px',
                    'border-radius': '0',
                    height: '12px'
                  }"
                >
                </ngx-skeleton-loader>
              </li>
            </ul>
          </td>
          <td>
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td class="td-display-col">
            <ul class="list-unstyled">
              <li>
                <strong
                  ><ngx-skeleton-loader
                    [theme]="{
                      width: '60%',
                      'border-radius': '0',
                      height: '12px'
                    }"
                  >
                  </ngx-skeleton-loader
                ></strong>
              </li>
              <li class="text-muted">
                <small class="f12"
                  ><ngx-skeleton-loader
                    [theme]="{
                      width: '120px',
                      'border-radius': '0',
                      height: '12px'
                    }"
                  >
                  </ngx-skeleton-loader
                ></small>
              </li>
            </ul>
          </td>
          <td class="td-display-col">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td>
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td class="td-display-col" *ngIf="false">
            <ul class="list-unstyled">
              <li>
                <ngx-skeleton-loader
                  [theme]="{
                    width: '120px',
                    'border-radius': '0',
                    height: '12px'
                  }"
                >
                </ngx-skeleton-loader>
              </li>
              <li class="small">
                <ngx-skeleton-loader
                  [theme]="{
                    width: '120px',
                    'border-radius': '0',
                    height: '12px'
                  }"
                >
                </ngx-skeleton-loader>
              </li>
            </ul>
          </td>
          <td class="td-display-col">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td>
            <ul class="list-unstyled">
              <ngx-skeleton-loader
                [theme]="{
                  width: '120px',
                  'border-radius': '0',
                  height: '12px'
                }"
              >
              </ngx-skeleton-loader>
            </ul>
          </td>
          <td align="center">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
          <td align="right">
            <ngx-skeleton-loader
              [theme]="{
                width: '120px',
                'border-radius': '0',
                height: '12px'
              }"
            >
            </ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="panel-footer">
    <div class="row">
      <div class="col-sm-6">
        <ngx-skeleton-loader
          [theme]="{
            width: '360px',
            'border-radius': '0',
            height: '25px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-6">
        <div class="text-right f12">
          <ngx-skeleton-loader
            [theme]="{
              width: '30%',
              'border-radius': '0',
              float: 'right',
              height: '20px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
