import { Component } from '@angular/core';
import { Referencia } from 'src/app/_models/dtos';
import { BaseBootstrapSelect } from '../base-bootstrap-select';


/**
 * Example of a bootstrap-select component using Object type for option values
 */
@Component({
    selector: 'multiselect-object-referencia',
    templateUrl: './multiselect-object-referencia.component.html',
})
export class MultiselectObjectReferenciaComponent extends BaseBootstrapSelect {
    // boxing of options (see base class), allows us to define the type in this component
    public get referencias(): Array<Referencia> {
        return this.options as Array<Referencia>;
    }
    public set referencias(val: Array<Referencia>) {
        this.options = val;
    }

    // boxing of selected options (see base class)
    public get selectedReferencias(): Array<Referencia> {
        return this.mySelections as Array<Referencia>;
    }
    public set selectedReferencias(val: Array<Referencia>) {
        this.mySelections = val;
    }

    /**
     * method to compare City objects for use in the compareWith directive
     * In our case, two cities are equal if their id's are equal
     */
    public compareReferencias(c1: Referencia, c2: Referencia): boolean {
        return c1 && c2 ? c1.Id === c2.Id : false;
    }

}
