import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IngresoComponent } from './ingreso.component';

const routes: Routes = [
  { path: "", redirectTo: 'cotizaciones', pathMatch: 'full' },
  { path: 'ingreso', component: IngresoComponent },
  { path: 'ingreso/:path', component: IngresoComponent },
  { path: "cotizaciones", loadChildren: () => import('./cotizacion/cotizacion.module').then(m => m.CotizacionModule) },
  { path: "cotizaciones/:paginaOrigen", loadChildren: () => import('./cotizacion/cotizacion.module').then(m => m.CotizacionModule) },
  { path: "vercotizacion/:idCotizacion/:idEmpresaC/:idOrgC/:idEmpresaV/:idOrgV", loadChildren: () => import('./ver-cotizacion/ver-cotizacion.module').then(m => m.VerCotizacionModule) },
  { path: "veroferta/:idCz/:idOf/:idEmpresaC/:idOrgC/:idEmpresaV/:idOrgV", loadChildren: () => import('./cotizacion/ver-oferta/ver-oferta.module').then(m => m.VerOfertaModule) },
  { path: "veroferta/:rutCliente/:rutOferente/:numeroCotizacion/:idOferta/:fromWS", loadChildren: () => import('./cotizacion/ver-oferta/ver-oferta.module').then(m => m.VerOfertaModule) },
  { path: "ofertarcotizacion/:idCotizacion/:idEmpresaC/:idOrgC", loadChildren: () => import('./ofertar-cotizacion/ofertar-cotizacion.module').then(m => m.OfertaCotizacionModule) },
  { path: "ofertarcotizacion/:idCotizacion/:idEmpresaC/:idOrgC/:paginaActual", loadChildren: () => import('./ofertar-cotizacion/ofertar-cotizacion.module').then(m => m.OfertaCotizacionModule) },
  { path: "reofertarcotizacion/:idCotizacion/:idEmpresaC/:idOrgC", loadChildren: () => import('./ofertar-cotizacion/ofertar-cotizacion.module').then(m => m.OfertaCotizacionModule) },
  { path: "reofertarcotizacion/:idCotizacion/:idEmpresaC/:idOrgC/:idOferta/:paginaActual", loadChildren: () => import('./ofertar-cotizacion/ofertar-cotizacion.module').then(m => m.OfertaCotizacionModule) },
  { path: "imprimiroferta/:idCz/:idOf/:idEmpresaC/:idOrgC/:idEmpresaV/:idOrgV", loadChildren: () => import('./cotizacion/imprimir-oferta/imprimir-oferta.module').then(m => m.ImprimirOfertaModule) },
  { path: "imprimircotizacion/:idCotizacion/:idEmpresaC/:idOrgC/:idEmpresa", loadChildren: () => import('./cotizacion/imprimir-cotizacion/imprimir-cotizacion.module').then(m => m.ImprimirCotizacionModule) },
  //{ path: "cotizacionesdescartadas", loadChildren: () => import('./cotizacion/consultar-descartadas/consultar-descartadas.module').then(m => m.ConsultarDescartadasModule) },
  { path: "dashboard", loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
